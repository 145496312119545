<template lang="pug">
  v-container#invmod(fluid='' tag='section')
    v-dialog(fullscreen="" v-model='dialodInvMod')
      v-card
        inventoryDataModal(@closeModal="closeInventoryModal" :productToEdit='productToEditNew' :forEditProduct='forEditProductNew')
    v-dialog(v-model='dialogProduct' width='500')
      template(v-slot:activator='{ on, attrs }')
      v-card
        v-card-title.text-h5.grey.lighten-2
          | Agregar Productos del Combo
        v-card-text
          v-row(style="margin-top: 20px;")
            v-col(cols='10' md='10' style='padding-top: 0; padding-bottom: 0;' v-if="$store.state.profile.profileLoaded.productComboValidation")
              v-autocomplete(v-model='productSelected' :items='productLst' return-object='return-object' item-text='completeData' label='Productos')
            v-col.py-0.pl-0.ml-0(cols="2" md="2" style="text-align: center;")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(v-on="on" icon text color="purple darken-3" @click="addNewProduct" style='background:#B18BE2;')
                    v-icon(style="font-size: 25px;") mdi-plus
                span.tooltips Crear Producto
            v-col(cols='12' md='12')
              v-text-field(v-model='productQuantity' label='Cantidad' placeholder='Cantidad' type='number' maxlength="25")
          v-data-table.mx-2#tablaDatosCombo(:headers="headers" v-show="!firstLoad" :items="lstProducts" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Productos por página'\}")
            template(v-slot:item.quit="{ item }")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(text="" icon="" color='red' v-on="on" @click="quitProduct(item)")
                    v-icon mdi-delete
                span.tooltips Quitar Producto
            v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
              | Sin resultados
            v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
              | Sin datos
          v-col(cols="12")
            v-btn.mr-0(color='primary' @click='addComboProduct' style="width: 100%;" :loading="loadingSave")
              | AGREGAR
        v-flex(xs12='', sm10='', offset-sm1='')
        v-divider
        v-card-actions
          v-spacer
            v-btn(color='red' text='' @click='dialogProduct = false')
              | Cancelar
          v-spacer
          v-btn(color='primary' text='' @click='dialogProduct = false')
            | Continuar
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-card(v-if="!userStatus")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Registro de clientes
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        div(style="widht: 100% text-align: center; padding-top: 100px;")
          v-alert(type='error')
            | En este momento su cuenta se encuentra inactiva. Debe ponerse en contacto con su proovedor para verificar este error, de lo contrario por favor active su plan para seguir ingresando nuevos combos, gracias.
        row
          v-col(cols='12' md="12").text-center
            //- //v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
            //-   v-icon(color="black") mdi-facebook
            v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/j2rtech/')")
              v-icon(color="black") mdi-instagram
            v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
              v-icon(color="black") mdi-whatsapp
    v-card(v-if="userStatus")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Inventario de Combos
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-form(ref='formInventory' v-model='validInventory' lazy-validation='' autocomplete="random-string")
          v-container.py-0
            v-row
              v-col(cols="12")
                v-alert(v-model="alertData" dismissible dense='' border='left' type='info')
                  | Puede ajustar el inventario generando transacciones de ingreso y salida de mercadería en la vista principal en el botón: {{" "}}
                  v-icon(small style="font-size: 30px;") mdi-transfer
                  | . {{ }} Si estás obligado a llevar contabilidad debes tener cuidado de modificar los datos del inventario sin una transacción de venta o compra.
                v-alert(v-model="alertData" dismissible dense='' border='left' type='warning')
                  | Recuerde actualizar el IVA al valor correspondiente a este producto.
              v-col(cols='12' md='12' v-if="profile ? profile.twoAccounts : false" style="padding-top: 17px;")
                v-select(style="margin-top: 0px; margin-bottom: 10px; font-size: 26px; height: 30px;" hide-details="" return-object v-model='selectAccount' :items="acoountsLst" label="Seleccione la cuenta de facturación" item-text='texto')
              v-col(cols="12" md="7")
                h2(style="padding-bottom: 20px; padding-top: 20px;") Características
                v-row
                  v-col(cols='12' md='12')
                    v-select(v-if='branchsProfile.length > 0' label='Sucursales' return-object='' :items='branchsProfile' item-text='name' v-model='product.branch')
                v-row
                  v-col(cols='12' md='4')
                    v-text-field(@keyup="product.codArticle = product.codArticle.toUpperCase()" v-model='product.codArticle' :rules="requeridos" label='Cod. Combo' placeholder='Cod. Combo' type='text' maxlength="25")
                  //- // v-col(cols='12' md='3')
                  //-   v-text-field(v-model='product.barcode' label='Cod. Barras' placeholder='Cod. Barras' type='text' maxlength="25")
                  v-col(cols='12' md='8')
                    v-text-field(@keyup="product.name = product.name.toUpperCase()" v-model='product.name' :rules="requeridos" label='Nombre' placeholder='Nombre' type='text' maxlength="150")
                  v-col(cols='12' md='12')
                    v-text-field(@keyup="product.description = product.description.toUpperCase()" v-model='product.description' :rules="requeridos" label='Descripción' placeholder='Descripción' type='text' maxlength="300")
                  v-col(cols="12" v-if="$store.state.profile.profileLoaded.productComboValidation")
                    v-btn.mr-0(color='secondary' @click='dialogProduct = true' style="width: 100%;" :loading="loadingSave")
                      | Agregar Productos del Combo
                  //- // v-col(cols='12' md='3')
                  //-   v-select(@change="changeTypeProduct" return-object v-model='product.type' :items="typeProductArr" label="Tipo de producto" item-text='texto')
                  //- // v-col(cols="12" md="9")
                  //-   v-autocomplete(id="searchInec" @keypress="filterDataInec()" :value="searchInecCode" v-model='product.inecCode' :items='lstInecodes' return-object='return-object' item-text='description' label='Código INEC')
                  //- // v-col(cols="12" md="3" style="textAlign: center; margin-top: 20px")
                  //-   label(style="font-size: 25px; font-weight: bold;") {{product.inecCode ? product.inecCode.code : 'NO ASIGNADO'}}
              v-col(cools="12" md="5")
                h2(style="padding-bottom: 20px; padding-top: 20px;") IVA / Valor
                v-row
                  v-col(cols='12' md='6' v-if="product.type.codigo.toString() === '01'")
                    v-text-field(v-model='product.quantity' :rules="requeridos" label='Cantidad' placeholder='Cantidad' type='text' maxlength="6" :readonly='forEditProduct === true' @keypress="isNumber($event)")
                  v-col(cols='12' md='6' v-if="product.type.codigo.toString() === '01'")
                    v-select(@change="changeIva" return-object v-model='product.tarifaiva' :items="electronicbillingtables.tarifaiva" label="Impuesto IVA" item-text='texto')
                  v-col(cols='12' v-if="product.type.codigo.toString() === '02'")
                    v-select(@change="changeIva" return-object v-model='product.tarifaiva' :items="electronicbillingtables.tarifaiva" label="Impuesto IVA" item-text='texto')
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="calculateVals2" v-model='product.activeValue' :rules="requeridos" label='Valor sin IVA $' placeholder='Valor sin IVA $' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols='12' md='6')
                    v-text-field(@keyup="calculateVals1" v-model='product.activeWValue' :rules="requeridos" label='Valor con IVA $' placeholder='Valor con IVA $' type='text' maxlength="8" @keypress="isNumber($event)")
                  v-col(cols="12")
                    v-btn.mr-0(color='primary' @click='saveProduct' style="width: 100%;" :loading="loadingSave")
                      | GUARDAR
            //- //h2(style="padding-bottom: 20px; padding-top: 20px;") Impuestos
            //- //v-row
            //-   v-col(cols='12' md='4')
            //-     v-select(return-object v-model='product.tarifaiva' :items="electronicbillingtables.tarifaiva" label="Impuesto IVA" item-text='texto')
            //-   //v-col(cols='12' md='4')
            //-     v-select(return-object v-model='product.irbpnr' :items="electronicbillingtables.irbpnr" label="Impuesto IRBPNR" item-text='texto')
            //-   //v-col(cols='12' md='4')
            //-     v-autocomplete(return-object v-model='product.tarifaice' :items="electronicbillingtables.tarifaice" label="Impuesto ICE" item-text='descripcion')
            v-row
              v-col(cols='12' md="6" offset-md="3")
                p(style='margin-top: 25px; text-align: center; font-size: 25px;')
                  | Valor sin IVA: $ {{(parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(2)) === 'NaN' ? '0.00': (parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(2))}}
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import * as functions from '../../../../functions'
  export default {
    components: {
      InventoryDataModal: () => import('@/views/dashboard/component/application/InventoryDataModal'),
    },
    props: {
      forEditProduct: {
        default: false,
        type: Boolean,
      },
      productToEdit: {
        default: function () {
          return {
            codArticle: '',
            barcode: '',
            name: '',
            description: '',
            type: {
              codigo: '02',
              texto: 'SERVICIO',
            },
            quantity: '1',
            activeValue: '',
            activeWValue: '',
            productKey: '',
            completeData: '',
            tarifaiva: {
              codigo: '2',
              texto: '12%',
              valor: '12',
            },
            irbpnr: '',
            tarifaice: '',
            totalValue: '',
            inecCode: null,
            combo: true,
          }
        },
        type: Object,
      },
    },
    data: () => ({
      acoountsLst: [
        {
          code: '001',
          texto: 'Ingresar datos de la Cuenta 1.',
        },
        {
          code: '002',
          texto: 'Ingresar datos de la Cuenta 2.',
        },
      ],
      selectAccount: {
        code: '001',
        texto: 'Ingresar datos de la Cuenta 1.',
      },
      forEditProductNew: false,
      dialodInvMod: false,
      lstProducts: [],
      ordenarPor: 'codArticle',
      search: '',
      cargando: false,
      firstLoad: false,
      headers: [
        {
          text: 'Cod. Producto',
          value: 'product.codArticle',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Producto',
          value: 'product.name',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Cantidad',
          value: 'quantity',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Quitar',
          align: 'center',
          value: 'quit',
          sortable: false,
        },
      ],
      productQuantity: '',
      productSelected: null,
      dialogProduct: false,
      searchInecCode: '',
      timeout: null,
      alertData: true,
      typeProductArr: [
        {
          codigo: '01',
          texto: 'BIEN',
        },
        {
          codigo: '02',
          texto: 'SERVICIO',
        },
      ],
      providerLstFilter: [],
      product: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '02',
          texto: 'SERVICIO',
        },
        quantity: '1',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
        inecCode: null,
        combo: true,
        lstProducts: [],
        branch: null,
      },
      productToEditNew: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      loadingSave: false,
      validInventory: true,
      requeridos: [
        value => !!value || 'Requerido',
      ],
      userStatus: true,
      profile: null,
    }),
    computed: {
      branchsSelected () {
        return this.$store.state.profile.branchSelected
      },
      branchsProfile () {
        return this.$store.state.profile.branchsProfile
      },
      lstInecodes () {
        return this.$store.state.facturacion.lstInecCodes
      },
      providerLst () {
        return this.$store.state.provider.lstProviders
      },
      planLst () {
        return this.$store.state.suscriptionPlan.plan
      },
      comboLst () {
        return this.$store.state.product.lstCombos
      },
      productLst () {
        return this.$store.state.product.lstProductsActive
      },
      electronicbillingtables () {
        return this.$store.state.facturacion.electronicbillingtables
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
    },
    watch: {
      profileLoaded () {
        if (this.profileLoaded) this.profile = this.profileLoaded
        if (this.profile) {
          if (this.profile.rucEstablecimieto) {
            if (this.selectAccount.code !== '002') {
              this.selectAccount = {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              }
            }
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: 'Ingresar datos de la Cuenta 2.',
              },
            ]
            if (this.profile.rucEstablecimieto2) {
              this.acoountsLst = [
                {
                  code: '001',
                  texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto : 'Ingresar datos de la Cuenta 1.',
                },
                {
                  code: '002',
                  texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
                },
              ]
            }
          }
        }
      },
      planLst () {
        if (this.comboLst.length >= this.planLst.combos) {
          this.userStatus = false
        } else {
          this.userStatus = true
        }
      },
      productToEdit () {
        this.product = this.productToEdit
        if (!this.product.branch) {
          this.product.branch = this.branchsSelected
        }
        if (this.productToEdit.lstProducts) {
          this.lstProducts = this.productToEdit.lstProducts
        } else {
          this.lstProducts = []
        }
        if (!this.product.activeWValue) {
          this.product.activeWValue = '0.00'
        }
        this.alertData = true
        this.calculateVals2()
        if (this.product.accountType) {
          if (this.product.accountType === '001') {
            this.selectAccount = {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            }
          } else {
            this.selectAccount = {
              code: '002',
              texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 1.',
            }
          }
        } else {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
        }
      },
      // proceso para filtrado y seleccion de proveedores
      providerLst () {
        this.providerLstFilter = this.providerLst
      },
    },
    mounted () {
      this.alertData = true
      this.electronicbillingtables = this.$store.state.facturacion.electronicbillingtables
      // para no usar la variable de props porque da error al modificar una variable prop
      this.product = this.productToEdit
      if (!this.product.branch) {
        this.product.branch = this.branchsSelected
      }
      if (this.productToEdit.lstProducts) {
        this.lstProducts = this.productToEdit.lstProducts
      } else {
        this.lstProducts = []
      }
      // se cargan todo los proveedores en providerLstFilter
      this.providerLstFilter = this.providerLst
      if (this.$store.state.product.lstCombos.length >= this.$store.state.suscriptionPlan.plan.combos) {
        this.userStatus = false
      } else {
        this.userStatus = true
      }
      this.profile = this.$store.state.profile.profileLoaded
      if (this.profile) {
        if (this.profile.rucEstablecimieto) {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
          this.acoountsLst = [
            {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            },
            {
              code: '002',
              texto: 'Ingresar datos de la Cuenta 2.',
            },
          ]
          if (this.profile.rucEstablecimieto2) {
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
              },
            ]
          }
        }
        if (this.product.accountType) {
          if (this.product.accountType === '001') {
            this.selectAccount = {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            }
          } else {
            this.selectAccount = {
              code: '002',
              texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 1.',
            }
          }
        } else {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
        }
      }
    },
    methods: {
      async closeInventoryModal () {
        this.dialodInvMod = false
      },
      addNewProduct () {
        this.forEditProductNew = false
        this.dialodInvMod = true
        this.productToEditNew = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '01',
            texto: 'BIEN',
          },
          quantity: '',
          activeValue: '',
          activeWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '2',
            texto: '12%',
            valor: '12',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
        }
      },
      quitProduct (product) {
        for (let i = 0; i < this.lstProducts.length; i++) {
          if (product.product.codArticle === this.lstProducts[i].product.codArticle) {
            this.lstProducts.splice(i, 1)
          }
        }
      },
      addComboProduct () {
        if (this.productQuantity === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe agregar la cantidad del producto.',
          }
          return
        }
        if (!this.productSelected) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el producto a agregar.',
          }
          return
        }
        this.lstProducts.push({
          product: this.productSelected,
          quantity: this.productQuantity,
        })
        this.productSelected = null
        this.productQuantity = ''
      },
      async getLstInec () {
        this.searchInecCode = document.getElementById('searchInec').value
        this.searchInecCode = functions.specialCharacters(this.searchInecCode).toUpperCase()
        if (!this.$store.state.facturacion.tokenAdmin) {
          await this.$store.dispatch('facturacion/adminLoginFac')
        }
        const data = {
          token: this.$store.state.facturacion.tokenAdmin,
          filterData: this.searchInecCode,
        }
        this.$store.dispatch('facturacion/getInecCodes', data)
      },
      filterDataInec () {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.getLstInec()
        }, 1000)
      },
      changeIva () {
        if (this.product.activeWValue.toString() !== '' || this.product.activeValue.toString() !== '') {
          if (this.product.activeValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeWValue = (parseFloat(this.product.activeValue) * parseFloat(valIva)).toFixed(2)
            } else {
              this.product.activeWValue = this.product.activeValue
            }
            return
          }
          if (this.product.activeWValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeValue = (parseFloat(this.product.activeWValue) / parseFloat(valIva)).toFixed(2)
            } else {
              this.product.activeValue = this.product.activeWValue
            }
          }
        }
      },
      calculateVals1 () {
        if (this.product.activeWValue) {
          if (this.product.activeWValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeValue = (parseFloat(this.product.activeWValue) / parseFloat(valIva)).toFixed(2)
            } else {
              this.product.activeValue = this.product.activeWValue
            }
          } else {
            this.product.activeValue = ''
          }
        } else {
          this.product.activeValue = ''
        }
      },
      calculateVals2 () {
        if (this.product.activeValue) {
          if (this.product.activeValue.toString() !== '') {
            const valIva = 1 + parseFloat(this.product.tarifaiva.valor) / 100
            if (parseFloat(valIva) > 0) {
              this.product.activeWValue = (parseFloat(this.product.activeValue) * parseFloat(valIva)).toFixed(2)
            } else {
              this.product.activeWValue = this.product.activeValue
            }
          } else {
            this.product.activeWValue = ''
          }
        } else {
          this.product.activeWValue = ''
        }
      },
      changeTypeProduct () {
        if (this.product.type.codigo.toString() === '02') {
          this.product.quantity = 1
        }
      },
      isNumber: function (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      async saveProduct () {
        if (this.$refs.formInventory.validate()) {
          if (parseFloat(this.product.cantidad) <= 0) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'La cantidad no puede ser menor de 1.',
            }
            return
          }
          const productCodeFilt = this.comboLst.filter(product => product.codArticle === this.product.codArticle)
          if (this.forEditProduct === true) {
            if (productCodeFilt.length > 1) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Ya existe un combo registrado con ese código.',
              }
              return
            }
          } else {
            if (productCodeFilt.length > 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Ya existe un combo registrado con ese código.',
              }
              return
            }
          }
          if (parseFloat(this.product.activeValue) <= 0) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El precio del combo no puede ser 0.',
            }
            return
          }
          if (this.product.productKey === '') {
            this.product.productKey = firebase.database().ref(`combos/${this.$store.state.user.user.uid}`).push().getKey()
          }
          this.product.activeValue = parseFloat(this.product.activeValue.toString().replace(',', '.'))
          this.product.totalValue = parseFloat(parseFloat(this.product.quantity) * parseFloat(this.product.activeValue)).toFixed(2)
          this.product.completeData = this.product.codArticle + ' | ' + this.product.name + ' | ' + this.product.description + ' | ' + this.product.barcode
          this.product.lstProducts = this.lstProducts
          this.product.accountType = this.selectAccount.code
          this.product.validChangeIva = true
          await this.$store.dispatch('product/setCombo', [this.product.productKey, this.product]).then(async resolve => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Combo guardado correctamente',
            }
            this.$emit('closeModal')
          }, reject => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar el combo',
            }
          })
        }
      },
    },
  }
</script>
